import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1025.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1025.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M5940 6790 c-293 -589 -535 -1070 -537 -1070 -2 0 -273 135 -603 300
-330 165 -604 300 -610 300 -7 0 -10 -513 -10 -1530 l0 -1530 2300 0 2300 0
-116 233 c-64 127 -149 298 -190 379 -41 81 -74 149 -74 153 0 3 22 3 48 -1
57 -7 63 -2 85 76 24 84 67 300 75 377 21 199 -1 507 -47 663 -10 36 -29 101
-41 145 -12 44 -35 108 -52 143 -16 34 -40 93 -54 130 -26 67 -60 132 -77 143
-5 3 -8 10 -8 15 1 5 -4 9 -10 9 -6 0 -17 11 -24 25 -8 14 -14 21 -15 16 0 -9
-69 79 -162 208 -21 28 -55 71 -75 96 -21 24 -50 60 -64 80 -34 46 -206 220
-218 220 -5 0 -19 14 -31 30 -12 17 -27 30 -34 30 -7 0 -23 12 -37 26 -13 14
-37 33 -54 41 -16 8 -41 25 -55 38 -27 24 -196 115 -215 115 -5 0 -26 10 -45
23 -52 34 -142 68 -202 76 l-53 6 -275 547 c-151 301 -277 549 -281 552 -3 3
-245 -476 -539 -1064z m368 -148 c32 -64 107 -211 167 -327 242 -472 299 -583
450 -880 87 -170 193 -379 236 -463 44 -83 79 -157 79 -162 0 -6 -360 170
-800 390 -440 220 -800 402 -800 405 0 12 35 75 42 75 4 0 8 6 8 13 0 8 21 54
46 103 25 49 96 186 156 304 61 118 158 307 216 420 58 113 111 215 116 227 6
12 14 19 18 17 4 -3 34 -58 66 -122z m1152 -626 c32 -24 66 -49 76 -54 34 -18
175 -168 200 -212 14 -24 38 -59 55 -78 33 -37 37 -44 102 -207 68 -171 101
-291 122 -445 18 -128 19 -234 4 -225 -11 7 -629 1240 -629 1256 0 15 10 10
70 -35z m-2463 -282 c357 -182 336 -169 322 -196 l-11 -21 28 20 c33 23 56 20
47 -5 -3 -9 -3 -14 2 -10 10 10 38 -19 30 -32 -3 -6 1 -7 9 -4 9 3 13 11 9 18
-4 6 1 5 10 -3 13 -10 14 -17 6 -27 -8 -10 -6 -12 10 -9 12 2 19 0 16 -5 -3
-4 2 -16 11 -25 9 -9 17 -27 17 -41 0 -24 1 -24 25 10 14 20 29 36 35 36 5 0
104 -49 220 -109 117 -60 329 -168 472 -241 143 -73 261 -134 263 -135 6 -5
-1523 -788 -1638 -839 -19 -8 -46 -22 -60 -30 -14 -8 -43 -24 -66 -34 l-41
-19 -6 210 c-8 241 -9 1637 -2 1637 2 0 134 -66 292 -146z m2446 -1314 c103
-204 185 -375 182 -380 -7 -11 -1875 -14 -1875 -3 0 8 1475 748 1495 751 6 1
95 -165 198 -368z"
          />
          <path
            d="M6230 6646 c0 -2 -6 -19 -14 -38 l-15 -34 35 0 c38 0 40 5 19 46 -14
26 -25 38 -25 26z"
          />
          <path
            d="M5732 5655 c-11 -23 -11 -30 0 -36 19 -12 40 -11 33 2 -4 6 -10 22
-14 36 l-7 25 -12 -27z"
          />
          <path
            d="M7044 5023 c-3 -13 -13 -23 -20 -23 -27 0 -13 -17 35 -43 27 -15 52
-27 55 -27 8 0 -26 67 -48 95 -16 20 -16 20 -22 -2z"
          />
          <path
            d="M7786 5370 c53 -107 99 -197 102 -200 28 -29 -143 327 -178 370 -11
14 23 -63 76 -170z"
          />
          <path
            d="M4740 5773 c0 -37 15 -61 23 -39 4 9 16 16 27 16 27 0 17 16 -21 35
-29 15 -29 15 -29 -12z"
          />
          <path
            d="M5198 5549 c6 -7 12 -24 12 -38 l0 -26 20 25 c21 27 18 34 -20 45
-20 6 -23 5 -12 -6z"
          />
          <path
            d="M5566 5332 c-18 -36 -18 -37 1 -19 11 9 30 17 42 17 24 0 20 14 -8
29 -15 8 -21 4 -35 -27z"
          />
          <path
            d="M6311 4978 c6 -11 9 -29 6 -39 -6 -23 8 -24 51 -1 l33 18 -51 21
c-49 21 -50 21 -39 1z"
          />
          <path
            d="M4746 4679 c-3 -17 -6 -59 -5 -93 l1 -61 28 60 c16 33 33 72 39 88 9
26 9 27 -11 16 -17 -9 -24 -8 -33 5 -11 14 -13 12 -19 -15z"
          />
          <path
            d="M5047 4319 c-20 -38 -34 -69 -30 -69 5 0 33 14 63 32 46 26 52 32 35
38 -12 4 -22 19 -25 38 -5 30 -6 29 -43 -39z"
          />
          <path
            d="M7195 4702 l-30 -16 31 -6 c17 -4 36 -10 42 -14 15 -9 15 3 -1 31
-11 20 -13 21 -42 5z"
          />
          <path
            d="M6040 4125 l-95 -45 92 0 c85 0 123 10 88 24 -20 7 -19 25 2 48 9 10
14 18 12 18 -2 0 -47 -20 -99 -45z"
          />
          <path
            d="M7500 4141 c0 -10 -7 -24 -15 -31 -25 -21 -17 -30 25 -30 l40 0 -20
40 c-23 44 -30 49 -30 21z"
          />
          <path
            d="M7303 7688 c-6 -24 -27 -101 -47 -173 -37 -130 -66 -249 -66 -272 0
-7 12 -16 28 -19 64 -16 344 -124 371 -143 16 -12 36 -21 44 -21 8 0 20 -7 27
-15 7 -8 20 -15 30 -15 16 0 41 -13 146 -75 18 -11 53 -31 76 -43 24 -13 64
-41 90 -63 26 -21 53 -39 61 -39 8 0 17 -7 20 -14 3 -8 20 -22 38 -32 40 -20
147 -107 218 -176 30 -29 67 -61 82 -70 15 -9 35 -31 44 -48 8 -16 21 -30 28
-30 6 0 27 -23 46 -50 36 -53 51 -62 51 -30 0 11 5 20 10 20 6 0 9 5 8 12 -2
7 4 12 12 13 9 0 14 8 12 19 -2 12 1 16 9 10 8 -5 11 -2 6 8 -7 21 3 31 18 18
8 -7 12 -5 12 5 0 8 -4 12 -8 9 -4 -2 -10 0 -14 6 -3 5 -1 10 4 10 6 0 11 11
11 25 0 14 5 25 11 25 5 0 8 4 5 8 -2 4 0 14 6 22 8 12 9 12 4 -2 -3 -10 -1
-20 5 -23 5 -4 8 -1 7 7 -2 7 2 12 8 11 6 -1 11 4 11 12 0 8 -4 12 -9 9 -4 -3
-8 0 -8 5 0 6 5 11 11 11 5 0 8 4 5 9 -3 5 3 11 14 14 15 4 27 -3 42 -23 11
-16 27 -26 34 -23 27 10 13 59 -30 111 -11 12 -21 30 -22 38 -2 9 -12 38 -23
65 -12 26 -21 55 -21 63 0 9 -8 16 -17 16 -10 0 -27 16 -38 38 -15 26 -27 36
-41 35 -15 -2 -27 10 -44 40 -27 48 -95 117 -115 117 -8 0 -20 10 -26 23 -13
24 -98 107 -110 107 -5 0 -12 6 -16 13 -9 15 -97 76 -186 129 -34 20 -66 40
-72 45 -24 20 -125 83 -146 92 -13 5 -71 32 -129 59 -58 28 -135 60 -170 72
-36 12 -74 27 -85 33 -33 18 -158 57 -185 57 -22 0 -27 -7 -37 -42z m1394
-958 c-3 -10 1 -24 9 -31 8 -6 14 -19 14 -27 0 -14 -2 -14 -14 -2 -8 8 -15 22
-16 30 -4 25 0 50 6 50 4 0 4 -9 1 -20z m-210 -196 c-3 -4 1 -13 9 -21 8 -9
13 -20 11 -27 -6 -18 -31 -13 -35 7 -4 21 4 47 14 47 4 0 5 -3 1 -6z"
          />
          <path
            d="M8840 6008 c0 -9 -9 -19 -19 -23 -30 -9 -34 -40 -12 -90 11 -24 27
-72 35 -105 9 -33 29 -92 46 -131 16 -39 30 -82 30 -96 0 -13 4 -33 10 -43 24
-44 63 -261 88 -480 8 -77 8 -418 0 -520 -12 -142 -59 -379 -108 -553 -19 -67
-21 -80 -9 -91 20 -18 116 -54 166 -61 24 -4 45 -11 49 -16 3 -5 12 -9 19 -9
8 0 65 -20 127 -44 62 -24 130 -47 151 -50 l38 -7 23 54 c22 48 78 253 104
382 10 44 20 118 27 195 2 14 7 63 11 110 17 167 9 552 -16 714 -17 112 -69
309 -127 486 -33 100 -120 288 -148 320 -7 8 -15 22 -18 30 -6 16 -9 -1 -9
-52 -1 -15 -4 -25 -9 -23 -4 3 -10 1 -14 -5 -3 -5 -18 -10 -32 -10 -23 0 -24
-2 -12 -17 12 -15 12 -16 -1 -8 -13 8 -13 7 -1 -8 7 -9 10 -17 6 -17 -4 0 -1
-8 6 -17 7 -10 -3 -3 -24 16 -20 19 -34 37 -32 41 2 3 -5 7 -16 7 -11 1 -17
-2 -14 -7 3 -4 -2 -11 -11 -14 -8 -3 -12 -2 -9 4 3 5 1 18 -4 27 -10 17 -10
17 -11 1 0 -10 -6 -18 -13 -18 -8 0 -14 7 -14 15 0 8 4 12 9 9 4 -3 8 0 8 5 0
6 -4 11 -10 11 -5 0 -10 7 -10 16 0 14 -3 14 -16 4 -12 -11 -15 -11 -12 1 2 9
-5 14 -19 14 -12 0 -30 9 -41 18 -11 10 -26 17 -34 16 -27 -3 -39 1 -32 13 4
7 3 8 -5 4 -6 -4 -18 -2 -26 4 -12 10 -18 10 -31 -1 -13 -10 -17 -10 -25 2 -7
12 -9 11 -9 -3z m40 -17 c0 -5 -10 -11 -22 -14 -22 -6 -22 -6 -4 8 23 17 26
18 26 6z m56 -13 c3 -4 -1 -5 -10 -4 -8 1 -18 5 -22 8 -3 4 1 5 10 4 8 -1 18
-5 22 -8z m64 -25 c0 -19 -13 -9 -16 12 -1 8 2 13 7 9 5 -3 9 -12 9 -21z m-86
-15 c-3 -4 -9 -8 -15 -8 -5 0 -9 4 -9 8 0 5 7 9 15 9 8 0 12 -4 9 -9z"
          />
          <path
            d="M8986 6012 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"
          />
          <path
            d="M8800 6000 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
          />
          <path d="M9117 5929 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
          <path
            d="M5363 5836 c-27 -13 -29 -22 -6 -30 17 -7 33 9 33 32 0 6 -1 11 -2
11 -2 -1 -13 -6 -25 -13z"
          />
          <path
            d="M8490 3970 c0 -4 9 -18 20 -31 l19 -24 1 26 c0 18 -6 28 -20 32 -11
3 -20 2 -20 -3z"
          />
          <path
            d="M8597 3714 c-7 -8 36 -44 52 -44 18 0 12 18 -11 34 -25 18 -32 19
-41 10z"
          />
          <path
            d="M8670 3612 c0 -4 7 -16 15 -26 15 -19 15 -19 15 1 0 11 -7 23 -15 27
-8 3 -15 2 -15 -2z"
          />
          <path
            d="M5838 2995 c-63 -17 -109 -47 -157 -101 -55 -63 -74 -126 -69 -228 9
-167 81 -243 298 -310 202 -63 240 -91 248 -179 4 -46 1 -57 -22 -84 -52 -61
-148 -67 -250 -16 -58 29 -156 126 -210 208 -25 37 -44 55 -58 55 -21 0 -21
-2 -14 -172 6 -133 11 -176 22 -188 14 -13 17 -12 34 8 l19 24 151 -22 c177
-26 267 -19 352 27 163 89 218 327 113 493 -42 66 -125 112 -290 160 -179 52
-218 81 -218 162 0 35 6 47 35 73 38 35 105 52 161 39 69 -15 148 -89 223
-208 21 -34 35 -46 49 -44 19 3 20 12 23 123 2 66 0 135 -3 153 -7 35 -27 42
-55 17 -15 -14 -26 -13 -101 5 -95 23 -210 25 -281 5z"
          />
          <path
            d="M7759 2983 c-5 -15 -85 -233 -178 -483 l-168 -455 -34 -5 c-26 -4
-35 -10 -37 -27 -4 -30 18 -35 143 -31 l100 3 3 27 c3 29 -6 34 -70 40 l-26 3
29 85 c16 47 36 104 45 128 l16 43 131 -3 130 -3 39 -115 c21 -63 41 -123 44
-132 4 -14 -3 -17 -38 -20 -33 -2 -44 -8 -46 -22 -2 -10 2 -22 10 -27 7 -5
115 -9 240 -9 208 0 228 2 237 18 14 26 -5 52 -37 52 -30 0 -14 -37 -198 465
-24 66 -75 203 -112 305 l-69 185 -72 3 c-71 3 -72 2 -82 -25z m-25 -360 c8
-27 28 -85 45 -131 17 -46 31 -90 31 -98 0 -11 -19 -14 -100 -13 -55 1 -100 3
-100 5 0 3 76 220 91 262 5 12 11 22 13 22 2 0 11 -21 20 -47z"
          />
          <path
            d="M9260 2568 c-208 -569 -190 -528 -235 -528 -39 0 -55 -20 -35 -45 19
-22 232 -22 251 1 22 26 -1 49 -50 49 -22 0 -41 5 -41 10 0 6 18 65 41 133
l42 122 131 0 132 0 42 -128 c23 -70 42 -131 42 -135 0 -4 -18 -7 -40 -7 -44
0 -61 -19 -40 -45 19 -23 462 -22 481 1 18 22 0 48 -37 52 -29 3 -30 7 -193
455 -90 249 -170 464 -178 480 -13 25 -17 27 -82 27 l-68 0 -163 -442z m120
69 c0 -12 10 -42 21 -67 35 -74 68 -179 59 -185 -4 -3 -51 -5 -103 -4 l-96 1
49 140 c28 78 50 143 50 145 0 2 5 1 10 -2 6 -3 10 -16 10 -28z"
          />
          <path
            d="M3730 2975 c0 -22 4 -25 33 -25 l34 0 116 -243 117 -244 0 -211 0
-212 -41 0 c-38 0 -40 -1 -37 -27 l3 -28 227 -3 228 -2 6 26 c8 32 -2 42 -46
46 l-35 3 -3 207 -4 207 40 98 c56 138 153 356 163 368 5 5 27 8 49 6 29 -2
42 2 46 13 5 14 8 14 27 1 12 -8 36 -15 54 -15 l33 0 0 -449 0 -450 -42 -3
c-38 -3 -43 -6 -43 -28 l0 -25 230 -3 c362 -5 492 18 580 99 66 61 92 172 64
273 -14 52 -79 119 -140 145 l-50 22 48 32 c70 47 98 103 98 197 0 91 -21 140
-78 181 -85 62 -96 63 -610 66 l-468 3 3 -22 c3 -21 9 -23 66 -26 34 -2 62 -5
62 -7 0 -9 -89 -204 -132 -290 -41 -80 -46 -87 -53 -65 -4 14 -36 89 -70 167
-85 191 -83 181 -43 185 28 2 34 7 36 31 l3 27 -235 0 -236 0 0 -25z m1418
-59 c20 -20 32 -82 32 -171 0 -144 -25 -195 -96 -195 l-34 0 0 196 0 197 43
-7 c23 -4 48 -13 55 -20z m11 -461 c43 -22 56 -65 56 -190 -1 -171 -25 -215
-117 -215 l-48 0 0 210 0 210 40 0 c23 0 54 -7 69 -15z"
          />
          <path
            d="M6400 2975 c0 -23 4 -25 45 -25 l45 0 0 -454 0 -455 -42 -3 c-36 -2
-44 -7 -46 -25 -3 -17 3 -24 23 -29 14 -3 146 -4 293 -1 230 4 278 7 345 25
177 48 272 138 323 307 28 92 26 307 -5 393 -55 159 -157 243 -336 277 -56 11
-152 15 -361 15 l-284 0 0 -25z m535 -46 c56 -16 97 -64 120 -140 17 -55 20
-94 20 -289 0 -204 -2 -231 -22 -295 -24 -75 -64 -125 -116 -144 -24 -9 -126
-16 -128 -8 0 1 -3 201 -6 445 l-6 442 49 0 c27 0 67 -5 89 -11z"
          />
          <path
            d="M8245 2989 c-4 -5 -9 -91 -12 -190 l-6 -179 22 0 c16 0 26 10 37 38
30 76 106 221 124 237 10 9 36 23 57 30 36 13 38 12 45 -8 3 -12 6 -210 7
-439 1 -473 8 -438 -83 -438 -42 0 -46 -2 -46 -24 0 -13 7 -27 16 -30 9 -3
130 -6 269 -6 247 0 255 1 265 21 18 32 -4 49 -61 49 l-49 0 0 440 0 440 24 0
c62 0 114 -66 172 -219 28 -74 35 -86 56 -86 23 0 23 1 26 125 2 69 0 153 -3
188 l-7 62 -423 0 c-288 0 -426 -3 -430 -11z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
